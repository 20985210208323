.petListContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.wrapperStyle {
    height: 100%;
    padding: 20px;
}

.listHeader {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    max-width: 360px;
    margin: auto;
}

.datePickerPetAge {
    width: 100%;
}

.labelForms {
    color: #666666;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
    padding: 0px;
    margin-top: 10px;

}

.petPicture {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 5px #00798C solid;
    object-fit: scale-down;
}

.publicPetView {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 2px;
    background-color: white;
    padding: 20px 0 20px 0;
}

.publicPetView>h1,
h3,
h4 {
    margin: 0;
}

.publicPetView>h3 {
    color: #666666;
    font-weight: 700;
}

.publicPetView>h4 {
    color: #666666;
    font-weight: 500;
    font-size: 15px;
}

.publicPetView>h1 {
    color: #00798C;
}